<template>
  <v-app>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer padless color='blue lighten-4'>
      <v-col class='text-center' cols='12'>
        © {{ new Date().getFullYear() }}
        <a href="https://henetwave.com/">Henet LLC</a>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
