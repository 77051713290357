<template>
  <v-container>

    <v-img
      src="../img/henetlogo.png"
      max-width="150"
    />

    <v-file-input
      style='margin: 1rem'
      show-size
      label='Choose file to upload'
      @change='load_file'
    />

    <div v-if='show_distance_spinner' class='text-center'>
      Loading...
      <v-progress-circular
        indeterminate
        color='primary'
      />
    </div>

    <time-series-chart
      style='margin: 1rem'
      v-if='show_distance_chart'
      :data='chart_data'
      :options='chart_options'
    />

    <div v-if='show_controls'>

      <v-row>
        <v-col cols='12' sm='2' md='2'>
          <v-text-field label='Start time [s]' v-model='start_time'/>
        </v-col>
        <v-col cols='12' sm='2' md='2'>
          <v-text-field label='End time [s]' v-model='end_time'/>
        </v-col>
        <v-col cols='12' sm='2' md='2'>
          <v-text-field label='High period cutoff [s]' v-model='high_period_cutoff'/>
        </v-col>
        <v-col cols='12' sm='2' md='2'>
          <v-text-field label='Low period cutoff [s]' v-model='low_period_cutoff'/>
        </v-col>
        <v-col cols='12' sm='2' md='2'>
          <v-text-field label='Time step [s]' v-model='time_step'/>
        </v-col>
      </v-row>

      <v-btn color='success' @click='upload_file()'>
        Get elevation
      </v-btn>

    </div>

    <div v-if='show_elevation_spinner' class='text-center'>
      Crunching data...
      <v-progress-circular
        indeterminate
        color='primary'
      />
    </div>

    <time-series-chart
      style='margin: 1rem'
      v-if='show_elevation_chart'
      :data='elevation_chart_data'
      :options='elevation_chart_options'
    />

    <div v-if='show_elevation_chart'>
      Significant wave height: {{ significant_wave_height(this.elevation).toFixed(2)}} m
    </div>

    <div v-if='show_elevation_chart' style='margin-top: 1rem'>
      <v-btn color='success' @click='download_waves_as_csv()'>
        Download as CSV
      </v-btn>
    </div>

  </v-container>
</template>

<script>
import TimeSeriesChart from '@/components/TimeSeriesChart.vue'

const BASEURL = ''
const sum = (array) => array.reduce((a, b) => a + b)
const average = (array) => sum(array) / array.length
//const argmax = array => array.indexOf(Math.max(...array))
const argmin = array => array.indexOf(Math.min(...array))

export default {

  name: 'HomeView',

  components: { TimeSeriesChart },

  data() {
    return {
      file: null,
      distance: [],
      elevation: [],
      time: [],
      elevation_time: [],
      crests_time: [],
      troughs_time: [],
      show_distance_chart: false,
      show_distance_spinner: false,
      show_elevation_chart: false,
      show_elevation_spinner: false,
      show_controls: false,
      start_time: 0,
      end_time: null,
      high_period_cutoff: 30,
      low_period_cutoff: 1,
      time_step: 0.1,
      plot_interval: 1
    }
  },

  methods: {

    arrays_to_positions(x, y) {
      // Formats arrays x and y to an array of objects {'x': xval, 'y': yval}
      return x.map((_, i) => {return {'x': x[i], 'y': y[i]}})
    },

    download_waves_as_csv() {
      // Download CSV data as a file
      let link = document.createElement('a')
      link.setAttribute('href', encodeURI(this.waves_to_csv))
      link.setAttribute('download', `${this.file.name.substring(0,this.file.name.length-3)}csv`)
      document.body.appendChild(link)
      link.click()
    },

    every_nth(x, n) {
      // Slice array x every n elements
      return x.filter((e, i) => i % n === n - 1)
    },

    async load_file(file) {
      // Read the data into memory
      this.show_distance_chart = false
      this.show_distance_spinner = true
      this.file = file
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.distance = reader.result.split('\r\n').map(x => Number(x))
        this.time = [...Array(this.distance.length).keys()].map(x => this.time_step * x)
        this.start_time = 0
        this.end_time = this.time[this.time.length - 1]
        this.show_elevation_chart = false
        this.show_distance_spinner = false
        this.show_distance_chart = true
        this.show_controls = true
      }, false)
      if (file) {
        reader.readAsText(file)
      }
    },

    async upload_file() {
      this.show_elevation_spinner = true
      let form_data = new FormData()
      form_data.append('file', this.file)
      form_data.append('start_time', this.start_time)
      form_data.append('end_time', this.end_time)
      form_data.append('time_step', this.time_step)
      form_data.append('high_period_cutoff', this.high_period_cutoff)
      form_data.append('low_period_cutoff', this.low_period_cutoff)
      const url = `${BASEURL}/upload`
      const response = await fetch(url, {method: 'post', body: form_data})
      const result = await response.json()
      this.elevation = result.elevation
      this.elevation_time = result.time
      this.crests_time = result.crests_time
      this.troughs_time = result.troughs_time
      this.show_elevation_spinner = false
      this.show_elevation_chart = true
      return result
    },

    significant_wave_height(elevation) {
      // Computes the significant wave height based on elevation time series
      return 4 * Math.sqrt(average(elevation.map(x => x**2)))
    },

  },

  computed: {

    crests_elevation() {
      return this.crests_time.map(
        t => this.elevation[argmin(this.elevation_time.map(x => (t - x)**2)) + 1]
      )
    },

    troughs_elevation() {
      return this.troughs_time.map(
        t => this.elevation[argmin(this.elevation_time.map(x => (t - x)**2)) + 1]
      )
    },

    wave_heights() {
      return this.crests_elevation.map((x, i) => x - this.troughs_elevation[i])
    },

    chart_data() {
      // Formats the array data to the Charts.js-compatible format.
      return {
        datasets: [
          {
            label: 'Altimeter distance',
            borderColor: '#1f77b4',
            borderWidth: 1,
            data: this.arrays_to_positions(
              this.time,
              this.distance
            ),
            fill: false,
            pointRadius: 0,
            showLine: true,
            cubicInterpolationMode: 'monotone'
          }
        ]
      }
    },

    chart_options() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        animation: {duration: 0},
        scales: {
          xAxes: [{
            type: 'linear',
            ticks: {
              min: parseInt(this.start_time),
              max: parseInt(this.end_time),
              stepSize: 60
            }
          }]
        }
      }
    },

    elevation_chart_data() {
      // Formats the array data to the Charts.js-compatible format.
      return {
        datasets: [

          // Elevation time series, line plot
          {
            label: 'Measured wave elevation',
            borderColor: '#ff7f0e',
            borderWidth: 1,
            data: this.arrays_to_positions(
              this.elevation_time,
              this.elevation
            ),
            fill: false,
            pointRadius: 0,
            showLine: true,
            cubicInterpolationMode: 'monotone'
          },

          // Crest markers
          {
            label: 'Crests',
            borderColor: '#ff0000',
            data: this.arrays_to_positions(
              this.crests_time,
              this.crests_elevation
            ),
            fill: false,
            pointRadius: 3,
            pointBackgroundColor: '#ff0000'
          },

          // Trough markers
          {
            label: 'Troughs',
            borderColor: '#0000ff',
            data: this.arrays_to_positions(
              this.troughs_time,
              this.troughs_elevation
            ),
            fill: false,
            pointRadius: 3,
            pointBackgroundColor: '#0000ff'
          },

          // Wave height markers
          {
            label: 'Individual wave heights',
            borderColor: '#00ffff',
            borderWidth: 1,
            data: this.arrays_to_positions(
              this.crests_time,
              this.wave_heights.map(x => x.toFixed(2))
            ),
            fill: false,
            showLine: true,
            pointRadius: 3,
            pointBorderColor: '#0000ff'
          }

        ]
      }
    },

    elevation_chart_options() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        animation: {duration: 0},
        scales: {
          xAxes: [{
            type: 'linear',
            ticks: {
              min: this.elevation_time[0],
              max: this.elevation_time[this.elevation_time.length - 1],
              stepSize: 60
            }
          }]
        }
      }
    },

    waves_to_csv() {
      // Format individual wave data into CSV and download as file.
      let header = `Wave,Troughs time,Troughs elevation,Crests time,Crests elevation,Wave height\r\n`
      let csvContent = `data:text/csv;charset=utf-8,${header}`
      for (let n = 0; n < this.crests_time.length; n++) {
        csvContent += `${n + 1},${this.troughs_time[n]},${this.troughs_elevation[n].toFixed(2)},${this.crests_time[n]},${this.crests_elevation[n].toFixed(2)},${this.wave_heights[n].toFixed(2)}\r\n`
      }
      return csvContent
    }

  }

}
</script>
