<script>
import { Scatter } from 'vue-chartjs'

export default {

  name: 'TimeSeriesChart',
  
  extends: Scatter,
  
  props: {
    
    data: {
      type: Object,
      default: () => {
        return {
          labels: [],
          datasets: [
            {
              data: []
            }
          ]
        }
      }
    },
    
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  
  },

  mounted() {
    // This hook happens only once.
    // Render the chart for the first time.
    this.renderChart(this.data, this.options)
    window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'})
  },

  watch: {

    data() {
      // Render the chart on any change to the data
      this.renderChart(this.data, this.options)
    },

    options() {
      // Render the chart on any change to the options
      this.renderChart(this.data, this.options)
    }

  }

}
</script>